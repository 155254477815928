import React from "react";
import {
  FaPhoneSquareAlt,
  FaRegEnvelope,
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import Logo from "../../assets/logo.svg";
import "./Footer.scss";
import Background from "../../assets/patner-bg.png";

export default function Footer() {
  return (
    <section
      style={{
        backgroundImage: "url(" + Background + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment:"fixed"
      }}
      className={["gp", "text-white", "d-lg-flex", "flex-column"].join(" ")}
    >
      <div
        className={["d-lg-flex", "fle-row", "justify-content-start","mt-5"].join(
          " "
        )}
      >
        <h5 className="col-4">
          For Data solutions and <br/> IT consulting services.
        </h5>
        <div>
          <h5>CALL US</h5>
          <br />
          <span style={{ fontSize: ".8em" }} className={["text-primary"]}>
            <FaPhoneSquareAlt />{" "}
            <a href="tel:+254 750 211117">+254 750 211117</a>
          </span>
        </div>
        <div style={{marginLeft:"10%"}}>
          <h5>MAIL US</h5>
          <br />
          <span style={{ fontSize: ".8em" }} className={["text-primary"]}>
            <FaRegEnvelope />{" "}
            <a href="mailto:sales@eITs.co.ke">sales@eITs.co.ke</a>
          </span>
        </div>
      </div>
      <div className="divider my-3"></div>
      <div
        className={["d-lg-flex", "fle-row", "justify-content-start"].join(
          " "
        )}
      >
        <div className="col-4">
          <img src={Logo} alt="eITs" width="150px" />
          <address className={["col-sm-12", "py-3"].join(" ")}>
           Victor Otieno and Associates  Building,
             <br /> Joseph Kangethe Rd, Nairobi, Kenya 
          </address>
          <div
            className={[
              "d-lg-flex",
              "justify-content-between",
              "col-3",
              "text-white",
              "align-items-center",
            ].join(" ")}
          >
            <a href="/">
              <FaFacebookSquare />
            </a>
            <a href="/">
              <FaTwitterSquare />
            </a>
            <a href="/">
              <FaLinkedin />
            </a>
          </div>
          {/* <iframe
            className={["mt-5"]}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7856365782454!2d36.784771914753954!3d-1.303629299049181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdf4fbcc545a90829!2zMcKwMTgnMTMuMSJTIDM2wrA0NycxMy4xIkU!5e0!3m2!1sen!2ske!4v1665056204232!5m2!1sen!2ske"
            width="260"
            title="eITs Offices"
            height="100"
            style={{border:0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
        <div>
          <h4>Menu</h4>
          <ul className={["list-unstyled"]}>
            <br />
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/services">Our Services</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
        <div style={{marginLeft:"10%"}}>
          <h5>Don’t miss out, Subcribe to our newsletter.</h5>
          <div className={["subscribe-fm"]}>
            <input
              type="email"
              placeholder="Enter your Email Address"
              className={["bg-white", "col-sm-7", "input"].join(" ")}
              required
            />
            <input
              type="button"
              value="SUBSCRIBE"
              className={["button", "bg-primary"].join(" ")}
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <span>© 2022 eITs. All rights reserved.</span>
      </div>
    </section>
  );
}
