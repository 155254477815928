import React from "react";
import OilCover from "../../assets/oil.png";
import AgricultureCover from "../../assets/agriculture.png";
import HealthCover from "../../assets/health.png";
import GovernmentCover from "../../assets/goverment.png";
import './Sector.scss';

export default function Sectors() {
  const sectors = [
    {
      id: 1,
      name: "Oil & Gas",
      cover: OilCover,
      text:"We work with companies in the Oil & Gas sector, to offer technology solutions through asset management procurement, asset tracking & management"
    },
    {
      id: 2,
      name: "Agriculture",
      cover: AgricultureCover,
      text:"We offer solutions such as geo-fencing, geo-mapping, asset tracking, hardware procurement & security solutions."
    },
    {
      id: 3,
      name: "Health",
      cover: HealthCover,
      text:"We streamline and bring efficiency to the healthcare sector through solutions ranging from system development & implementation, training, asset/ patient management & tracking, data storage and cybersecurity."
    },
    {
      id: 4,
      name: "Government",
      cover: GovernmentCover,
      text:"Whether the need is security, human identity verification, improving staff productivity, modernizing data storage and management or the need to track assets and resources, eITs has you covered."
    },
  ];
  return (
    <section className={["gp"]}>
      <div className={["py-5","text-center"].join(" ")}>
        <h1 className={["py-3","text-primary"].join(" ")}>Sector Specific Use Cases</h1>
        <p style={{fontSize:'1.5em'}} className={["py-3"].join(" ")}>
        Cumulatively, we work in all sectors to provide internal solutions that help with simple tracking financial solutions, track accounting, supply chain, HR and Finance.
        </p>
      </div>
      <section className={["d-flex","justify-content-center", "flex-row", "flex-wrap"].join(" ")}>
        {sectors.map((item) => {
          return (
            <div
              key={item.id}
              className={[
                "col-sm-5",
                "m-2",
                "py-5",
                "px-4",
                "text-white",
                "font-weight-bold",
                "rounded",
                "click"
              ].join(" ")}
              style={{
                backgroundImage: "url(" + item.cover + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h2 className="py-4">{item.name}</h2>
              <p>{item.text}</p>
            </div>
          );
        })}
      </section>
      <div className="b-spacer"></div>
    </section>
  );
}
