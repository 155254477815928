import React from "react";
import Realite from "../../assets/realite.png";
import Acronis from "../../assets/acronis.png";
import Sato from "../../assets/sato.png";
import Iot from "../../assets/iot.png";
import Dell from "../../assets/dell.png";
import Entrust from "../../assets/entrust.png";
import Reload from "../../assets/reload.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

export default function Partners() {
  const partners = [
    {
      id: 1,
      image: Realite,
    },
    {
      id: 2,
      image: Acronis,
    },
    {
      id: 3,
      image: Sato,
    },
    {
      id: 4,
      image: Iot,
    },
    {
      id: 5,
      image: Dell,
    },
    {
      id: 4,
      image: Entrust,
    },
    {
      id: 5,
      image: Reload,
    },
  ];
  return (
    <section className={["bg-black", "text-center"].join(" ")}>
      <div className="t-spacer"></div>
      <article>
        <h1 className={["text-white", "py-5"].join(" ")}>
          We carefully pick our partners in order to give you the best
        </h1>
        <p
          style={{ fontSize: "1.5em" }}
          className={["pb-5", "text-justify", "text-white"].join(" ")}
        >
          We are authorized to distribute software and hardware from the
          following companies:
        </p>
      </article>
      <article
        className={["d-flex", "justify-content-center", "bg-white"].join(" ")}
      >
        <Swiper
          freeMode={true}
          grabCursor={true}
          modules={[FreeMode, Autoplay, Pagination]}
          className={"mySwiper"}
          slidesPerView={5}
          pagination={{ clickable: true }}
          spaceBetween={30}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {partners.map((prod) => (
            <SwiperSlide key={prod.id} className="py-5 align-items-center">
              <img src={prod.image} alt="iets-partner" className="img-fluid" />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div>
            {partners.map((item, index) => (
              <div key={index} style={{ height: 200, background: "#EEE" }}>
                  <img src={item.image} alt="iets-partner" />
              </div>
            ))}
        </div> */}
      </article>
    </section>
  );
}
