import React from "react";
import OurService from '../../components/OurService';

export default function Services() {
  return (
    <section>
      <div className="mt-5" style={{ paddingTop: "7%",marginLeft:'8%' }}>
        <h1 className="py-4">Our Approach</h1>
        <div className="d-flex flex-row flex-wrap justify-content-around col-12 mb-5">
          <div className="col-3 card bg-black p-4">
            <h1 className="text-white">Diagnosis</h1>
            <p className="pt-4 text-white">
              We strive to tailor-make solutions that are adaptive to the uniqueness of your infrastructure. We do this after a thorough scrutiny of your business functions, considering all aspects of your operations, to understand with precision the origin, the cause and the effect of the challenges faced as well as the best available solutions.
            </p>
          </div>
          <div className="col-3 card bg-black p-4">
            <h1 className="text-white">Collaboration</h1>
            <p className="pt-4 text-white">
              Teamwork makes the dream work, is an adage that still rings true for us. We work in partnership with you to curate solutions that best fit your organisational strategy and mode of operations. We leverage our internal team and global network of experts to ensure the recommended solution meets your needs. We work with your team to understand the unique needs and context of your sector and adapt the solutions and infrastructure proposed.
            </p>
          </div>
          <div className="col-3 card bg-black p-4">
            <h1 className="text-white">Execution</h1>
            <p className="pt-4 text-white">
              Implementation excellence is where critical expertise and resources are needed, and that’s what we have invested in building for our customers. Our engagement will help you obtain any hardware and infrastructure equipment, as well as the specific set of services required to successfully implement your solution end-to-end.
            </p>
          </div>
        </div>
      </div>
      <OurService />
    </section>
  );
}
