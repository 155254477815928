import React from 'react'
import {BrowserRouter,Routes,Route,Navigate} from "react-router-dom";
import Home from '../pages/Home';
import About from '../pages/About';
import Services from '../pages/Services';
import Contacts from '../pages/ContactUs';
import Products from '../pages/Products';

export default function Router() {
  return (
<BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/contact-us" element={<About />} /> */}
        <Route path="/solutions" element={<Services />} />
        <Route path="/contact-us" element={<Contacts />} />
        {/* <Route path="/products" element={<Products />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
</BrowserRouter>
  )
}
