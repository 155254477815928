import './App.scss';
import Router from './routes/router';
import Header from './components/Header';
import Footer from './components/Footer';
import Maintaince from './pages/Maintainance';


function App() {
  return (
    <>
    <Header/>
    <Router/>
    <Footer/>
    {/* <Maintaince/> */}
    </>
  );
}

export default App;
