import React from "react";
import Background from "../../assets/service-bg.png";
import { Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import DataCaptureIcon from "../../assets/data-cap.png";
import DataStorageIcon from "../../assets/data-st.png";
import DataAnalysisIcon from "../../assets/data-ana.png";
import DataSecurityIcon from "../../assets/data-sec.png";

export default function OurService() {
  const services = [
    {
      id: 1,
      icon: DataCaptureIcon,
      title: "Solutions for Data Collection & Analysis/ Big Data",
    },
    {
      id: 2,
      icon: DataStorageIcon,
      title: "Solutions for Data Backup,  Storage & Disaster Recovery",
    },
    {
      id: 4,
      icon: DataSecurityIcon,
      title: "Solutions for Cyber Security & Data Protection",
    },
    {
      id: 3,
      icon: DataAnalysisIcon,
      title: "Professional Services",
    },
  ];
  return (
    <section
      style={{
        backgroundImage: "url(" + Background + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment:"fixed",
      }}
      className={["gp","text-center", "align-items-center"].join(" ")}
    >
      <h1 className={["text-white", "py-5"].join(" ")}>
        What we do
      </h1>
      <p style={{fontSize:'1.5em'}} className={["text-white"]}>
      We combine different sets of services, software, hardware and consulting expertise to come up with solutions that help African companies grow and thrive in the spaces they exist. 
      We position our clients to use their data effectively and efficiently so that they become pace-setters in their distinctive markets.
      </p>
      <section
        className={[
          "py-5",
          "d-flex",
          "flex-row",
          "flex-wrap",
          "justify-content-center",
          "align-content-start",
        ].join(" ")}
      >
        {services.map((item) => {
          return (
            <Card
              key={item.id}
              className={[
                "col-sm-5",
                "m-2",
                "py-2",
              ].join(" ")}
            >
              <Card.Header
                className={[
                  "border-1","border-black"
                ].join(" ")}
              >
                <img width="60px" src={item.icon} alt="Data capture" />
              </Card.Header>
              <Card.Body
                className={[
                  "py-4","text-start"
                ].join(" ")}
              >
                <Card.Title>{item.title}</Card.Title>
                <p className={"font-weight-light"}>{item.text}</p>
                <h6
                  className={[
                    "col-sm-2",
                    "py-4",
                    "click",
                    "text-primary"
                  ].join(" ")}
                >
                  {" "}
                  Explore <FaArrowRight className="text-primary" />{" "}
                </h6>
              </Card.Body>
            </Card>
          );
        })}
      </section>
    </section>
  );
}
