import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/logo.svg";
import {
  FaPhoneSquareAlt,
  FaRegEnvelope,
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import "./Header.scss";

export default function Header() {

  
  return (
    <header
      className="
        bg-white text-black d-flex flex-row justify-content-between align-items-center gp fixed-top"
      style={{ boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)" }}
    >
      <section >
        <figure className="col-4">
          <img className="logo" src={Logo} alt="eITs" />
        </figure>
      </section>
      <section>
        {/* <article
          className={[
            "d-none",
            "d-lg-flex",
            "align-center",
            "justify-content-between",
            "mb-4",
            "mt-3",
          ].join(" ")}
        >
          <div
            className={[
              "d-flex",
              "justify-content-between",
              "col-sm-3",
              "align-items-center",
            ].join(" ")}
          >
            <FaPhoneSquareAlt /> <span className="span">+254 750 211117</span>
          </div>
          <div
            className={[
              "d-flex",
              "justify-content-between",
              "col-sm-3",
              "align-items-center",
            ].join(" ")}
          >
            <FaRegEnvelope /> <span className="span">sales@eITs.co.ke</span>
          </div>
          <div
            className={[
              "d-flex",
              "justify-content-between",
              "col-sm-2",
              "align-items-center",
            ].join(" ")}
          >
            <FaFacebookSquare />
            <FaTwitterSquare />
            <FaLinkedin />
          </div>
        </article> */}
        <Navbar expand="lg">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={["mt-3", "bg-white", "p-2"].join(" ")}
          />
          <Navbar.Collapse id="basic-navbar-nav" className={"navbar-left"}>
            <br />
            <Nav className={["me-auto", "left"].join(" ")}>
              <Nav.Link href="/" activeClassName="active" className="text-black fw-bold link">
                Home
              </Nav.Link>
              <Nav.Link activeClassName="active" href="/solutions" className="text-black fw-bold link">
                Solutions
              </Nav.Link>
              <Nav.Link activeClassName="active" href="/#usecases" className="text-black fw-bold link">
                Use Cases
              </Nav.Link>
              <Nav.Link activeClassName="active" href="/#partners" className="text-black fw-bold link" >
                Partners
              </Nav.Link>
              <Nav.Link href="/contact-us" className="m-0 p-0">
                <button className="btn-contact">Contact Us</button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
    </header>
  );
}
