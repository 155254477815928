import React from "react";
import { FaPhoneSquareAlt, FaRegEnvelope } from "react-icons/fa";
import { Form, Button } from 'react-bootstrap';

export default function Contacts() {
  return (
    <section className="d-flex justify-content-around mb-5" style={{ marginTop: "10%",marginLeft:"7%" }}>
      <div className="bg-black w-50 p-5 rounded">
        <h1 className="text-white">Get In Touch!</h1>
        <Form className="my-5">
          <Form.Group className="mb-3 text-light" controlId="formBasicName">
            <Form.Label>Your Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" />
          </Form.Group>
          <Form.Group className="mb-3 text-light" controlId="formBasicEmail">
            <Form.Label>Your Email</Form.Label>
            <Form.Control type="text" placeholder="Enter email address" />
          </Form.Group>
          <Form.Group className="mb-3 text-light" controlId="formBasicText">
            <Form.Label>Subject</Form.Label>
            <Form.Control type="text" placeholder="Enter subject" />
          </Form.Group>
          <Form.Group className="mb-3 text-light" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={5} placeholder="Enter message here" />
          </Form.Group>
          <Button variant="primary" type="submit" className="col-lg-2">
            SEND
          </Button>
        </Form>
      </div>
      <article className="d-flex flex-column align-items-center justify-content-around" style={{height:"30%"}}>
        <div className="p-2 text-center d-flex flex-column">
          <h5>Talk to us</h5>
          <div className="t-spacer"></div>
          <span style={{ fontSize: ".8em" }} className="text-black mt-3">
            <FaPhoneSquareAlt />{" "}
            +254 750 211117
          </span>
          <span style={{ fontSize: ".8em" }} className="text-black my-3">
            <FaRegEnvelope />{" "}
            sales@eITs.co.ke
          </span>
        </div>
        <div className="mt-5 text-center d-flex flex-column">
          <h5>Visit us</h5>
          <p className="my-2">Joseph Kangethe Rd, Nairobi Kenya</p>
          <iframe
            className={["mt-5"]}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7856365782454!2d36.784771914753954!3d-1.303629299049181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdf4fbcc545a90829!2zMcKwMTgnMTMuMSJTIDM2wrA0NycxMy4xIkU!5e0!3m2!1sen!2ske!4v1665056204232!5m2!1sen!2ske"
            width="300"
            title="eITs Offices"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </article>
    </section>
  );
}
