import React from "react";
import "./Hero.scss";


const Hero = ({ heading }) => {
  return (
    <div className="hero">
      {/* Overlay */}
      <div className="overlay" />
      <div className="heroText">
        <h2 className="typing">| {heading}</h2>
        <hr/>
        <p>
          We are a technology company that leverages the power of data to
          provide solutions that help businesses improve their bottom lines.
        </p>
        <p>
          We do this by providing them with the infrastructure, systems,
          software and expertise required for seamless implementation.
        </p>
      </div>
    </div>
  );
};

export default Hero;
