import React from "react";
import Hero from "../../components/Hero";
import Offer from "../../components/Offer";
import OurService from "../../components/OurService";
import Sectors from "../../components/Sectors";
import Partners from "../../components/Partners";
import Testimonials from "../../components/Testimonials";
export default function Home() {
  return (
    <div>
      {/* <ProductSlider /> */}
      <Hero heading={'Who we are'} />
      <Offer />
      <OurService />
      <div id="usecases"> 
      <Sectors />
      </div>
      <div id="partners">
      <Partners />
      </div>
      {/* <Testimonials /> */}
    </div>
  );
}
