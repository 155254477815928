import React from "react";
import Gear from "../../assets/gear.jpeg";
export default function Offer() {
  return (
    <section className={["gp", "align-content-center"].join(" ")}>
      <div className="t-spacer"></div>
      <section className={["d-lg-flex", "py-5", "justify-content-between"].join(" ")}>
        <article>
          <img className={["w-75", "rounded-4"].join(" ")} src={Gear} alt="eits" />
        </article>
        <article className={["col-lg-9", "p-2"].join(" ")}>
          <h1>Why we Exist</h1>
          <p style={{ fontSize: '1.5em' }} className={["py-5", "text-justify"].join(" ")}>
            We exist to help build a more prosperous Africa. To do this, we solve critical business challenges through the deployment of cutting-edge technology for our clients.
          </p>
        </article>
      </section>
      <div className="b-spacer"></div>
    </section>
  );
}
